import { signIn } from "next-auth/react"
import { useState } from "react"
import Head from 'next/head'
import { useForm, SubmitHandler } from 'react-hook-form';
import { useRouter } from "next/router";
import toast, { Toaster } from 'react-hot-toast';
import { Alert, Button, Label, Spinner, TextInput } from "flowbite-react"
import { FaInfoCircle } from "react-icons/fa";
import {  FiLock, FiMail } from "react-icons/fi";
type FormValues = {
  email: string
  password: string
};

export default function Handle() {

  const router = useRouter();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const callbackUrl = decodeURI((router.query?.callbackUrl as string) ?? "/dashboard");
  const { register, handleSubmit, formState: { errors } } = useForm<FormValues>();
  const onSubmit: SubmitHandler<FormValues> = data => {
    check(data)
  };

  const check = async (data: FormValues) => {
    setLoading(true)
    const { email, password } = data;
    const result = await signIn("credentials", {
      email,
      password,
      callbackUrl: callbackUrl ?? "/dashboard",
      redirect: false,
    });



    if (result?.error) {
      toast.error(result.error)
      setError(result.error);
    } else {
      setError("");
    }

    if (result?.ok) {
      //toast.success("Login")
      setIsSuccess(true)
      router.push(callbackUrl);
    }

    setLoading(false)
  }


  return (
    <>
      <Head>
        <title>Login</title>
      </Head>
      <Toaster position="top-center" reverseOrder={false} />
      <div className="isolate bg-white">

        <BgHeader />

        <main>
          <div className="relative px-6 lg:px-8">
            <div className="mx-auto max-w-sm py-10 ">
              <div className="text-center">
                <h1 className="text-3xl font-bold tracking-tight text-gray-900 ">
                  Login Area
                </h1>
                <p className="mt-4 text-sm leading-8 text-gray-600">
                  Gunakan alamat email dan password yang telah didaftarkan
                </p>
              </div>
              <div className="bg-white shadow-md rounded-md mt-6 p-4">

                {!!error && <Alert className="mb-2" icon={FaInfoCircle} color="failure" >

                  <span className="font-semibold">ERROR</span> {' '} {error}

                </Alert>}


                {isSuccess && <MessageSuccessLogin />}


                {!isSuccess &&
                  <form onSubmit={handleSubmit(onSubmit)}>

                    <div className="mb-2">
                      <div className="mb-2 block">
                        <Label
                          htmlFor="email"
                          value="Alamat Email"
                        />
                      </div>
                      <TextInput
                        {...register("email", {
                          required: "Email is required",
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "invalid email address"
                          }
                        })}
                        id={'email'}
                        icon={FiMail}
                        placeholder={''}
                        disabled={loading}
                        type="text" />
                      {errors?.email && <p className="text-sm text-red-500">{errors.email.message}</p>}
                    </div>

                    <div className="mb-4">
                      <div className="mb-2 block">
                        <Label
                          htmlFor="password"
                          value="Password"
                        />
                      </div>
                      <TextInput
                        {...register("password", {
                          required: "Password is required",
                          minLength: {
                            value: 6,
                            message: "minimal 6 karakter",
                          }
                        })}
                        id={'password'}
                        icon={FiLock}
                        disabled={loading}
                        type="password" />
                      {errors?.password && <p className="text-sm text-red-500">{errors.password.message}</p>}
                    </div>

                    {/* <div className="mb-6">
                                            <button className="btn" type="submit">
                                                {loading ? "Loading..." : "Login"}
                                            </button>
                                        </div> */}

                    <Button type="submit" className="w-full" disabled={loading}>
                      {loading ?
                        <div className="">
                          <Spinner size="sm" className="mr-1" light={true} /> loading...
                        </div>
                        : "Login"}
                    </Button>

                  </form>}
              </div>
            </div>

            <BgFooter />

          </div>
        </main>
      </div>
    </>
  )
}

const MessageSuccessLogin = () => {
  return (<div className="flex flex-col gap-2 my-12">

    <div className="text-center">
      <Spinner aria-label="Center-aligned spinner example" />
    </div>

    <div className="text-center text-gray-500">
      Mengarahkan anda ke halaman dashboard
    </div>

  </div>)
}

const BgHeader = () => {
  return (<div className="absolute inset-x-0 top-[-10rem] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[-20rem]">
    <svg
      className="relative left-[calc(50%-11rem)] -z-10 h-[21.1875rem] max-w-none -translate-x-1/2 rotate-[30deg] sm:left-[calc(50%-30rem)] sm:h-[42.375rem]"
      viewBox="0 0 1155 678" >
      <path
        fill="url(#45de2b6b-92d5-4d68-a6a0-9b9b2abad533)"
        fillOpacity=".3"
        d="M317.219 518.975L203.852 678 0 438.341l317.219 80.634 204.172-286.402c1.307 132.337 45.083 346.658 209.733 145.248C936.936 126.058 882.053-94.234 1031.02 41.331c119.18 108.451 130.68 295.337 121.53 375.223L855 299l21.173 362.054-558.954-142.079z" />
      <defs>
        <linearGradient
          id="45de2b6b-92d5-4d68-a6a0-9b9b2abad533"
          x1="1155.49"
          x2="-78.208"
          y1=".177"
          y2="474.645"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9089FC" />
          <stop offset={1} stopColor="#FF80B5" />
        </linearGradient>
      </defs>
    </svg>
  </div>)
}

const BgFooter = () => {
  return (<div className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]">
    <svg
      className="relative left-[calc(50%+3rem)] h-[21.1875rem] max-w-none -translate-x-1/2 sm:left-[calc(50%+36rem)] sm:h-[42.375rem]"
      viewBox="0 0 1155 678" >
      <path
        fill="url(#ecb5b0c9-546c-4772-8c71-4d3f06d544bc)"
        fillOpacity=".3"
        d="M317.219 518.975L203.852 678 0 438.341l317.219 80.634 204.172-286.402c1.307 132.337 45.083 346.658 209.733 145.248C936.936 126.058 882.053-94.234 1031.02 41.331c119.18 108.451 130.68 295.337 121.53 375.223L855 299l21.173 362.054-558.954-142.079z" />
      <defs>
        <linearGradient
          id="ecb5b0c9-546c-4772-8c71-4d3f06d544bc"
          x1="1155.49"
          x2="-78.208"
          y1=".177"
          y2="474.645"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#9089FC" />
          <stop offset={1} stopColor="#FF80B5" />
        </linearGradient>
      </defs>
    </svg>
  </div>)
}

